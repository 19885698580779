import { ITreatment } from 'app/shared/model/treatment.model';
import React from 'react';
import './TreatmentItem.scss';
import { IConsultantOpinion } from 'app/shared/model/consultant-opinion.model';
import { ICourseOfTreatment } from 'app/shared/model/course-of-treatment.model';
import { IParaclinicalCheck } from 'app/shared/model/paraclinical-check.model';

const TreatmentItem = (props: ITreatment) => {
  return (
    <div className="treatment_detail">
      <label className="content_treatment">{props.note}</label>
      <label className="owner_item">{props.clinicUser.displayName}</label>
    </div>
  );
};

const OpinionItem = (props: IConsultantOpinion) => {
  return (
    <div className="treatment_detail">
      <label className="content_treatment">{props?.detail}</label>
      <label className="owner_item">{props.clinicUser.displayName}</label>
    </div>
  );
};

const ConclusionItem = (props: ICourseOfTreatment) => {
  return (
    <div className="treatment_detail">
      <label className="content_treatment">{props?.conclusion}</label>
      {/* <label className="owner_item">{props.clinicUser.displayName}</label> */}
    </div>
  );
};

const ParaclincalItem = (props: IParaclinicalCheck) => {
  return (
    <div className="treatment_detail">
      <label className="content_treatment">{props?.noiDungCanChamSoc}</label>
      <label className="owner_item">Nhịp tim: {props?.heartRate}</label>
      <label className="owner_item">Huyết áp tâm thu: {props?.hatt}</label>
      <label className="owner_item">Huyết áp tâm trương: {props?.hattr}</label>
      {/* <label className="content_treatment">Huyết áp tâm thu: {props?.hatt}</label>
      <label className="content_treatment"> Huyết áp tâm trương: {props?.hattr}</label> */}
    </div>
  );
};

export { TreatmentItem, OpinionItem, ConclusionItem, ParaclincalItem };
