import dayjs from 'dayjs';
import { IClinic } from 'app/shared/model/clinic.model';
import { IUser } from 'app/shared/model/user.model';

export interface IClinicUser {
  id?: number;
  creadtedDate?: dayjs.Dayjs | null;
  isModerator?: boolean | null;
  isDoctor?: boolean | null;
  note?: string | null;
  isActive?: boolean | null;
  displayName?: string | null;
  clinic?: IClinic | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IClinicUser> = {
  isModerator: false,
  isDoctor: false,
  isActive: false,
};
