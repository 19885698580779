import { Button, Input } from 'antd';
import React, { useState } from 'react';
import './CommentInput.scss';
const { TextArea } = Input;
const CommentInput = (props: { sendOpinion: (val: string) => void; hidden?: boolean }) => {
  const { sendOpinion, hidden } = props;
  const [opinion, setOpinion] = useState('');

  return (
    <div className="comment_input_area" hidden={hidden}>
      <TextArea rows={4} value={opinion} onChange={e => setOpinion(e.target.value)} />
      <Button
        type="primary"
        className="send_button"
        onClick={() => {
          sendOpinion(opinion);
          setOpinion('');
        }}
      >
        Gửi
      </Button>
    </div>
  );
};
export default CommentInput;
