import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './clinic-user.reducer';

export const ClinicUserDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const clinicUserEntity = useAppSelector(state => state.clinicUser.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="clinicUserDetailsHeading">
          <Translate contentKey="clinicManagementApp.clinicUser.detail.title">ClinicUser</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{clinicUserEntity.id}</dd>
          <dt>
            <span id="creadtedDate">
              <Translate contentKey="clinicManagementApp.clinicUser.creadtedDate">Creadted Date</Translate>
            </span>
          </dt>
          <dd>
            {clinicUserEntity.creadtedDate ? (
              <TextFormat value={clinicUserEntity.creadtedDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="isModerator">
              <Translate contentKey="clinicManagementApp.clinicUser.isModerator">Is Moderator</Translate>
            </span>
          </dt>
          <dd>{clinicUserEntity.isModerator ? 'true' : 'false'}</dd>
          <dt>
            <span id="isDoctor">
              <Translate contentKey="clinicManagementApp.clinicUser.isDoctor">Is Doctor</Translate>
            </span>
          </dt>
          <dd>{clinicUserEntity.isDoctor ? 'true' : 'false'}</dd>
          <dt>
            <span id="note">
              <Translate contentKey="clinicManagementApp.clinicUser.note">Note</Translate>
            </span>
          </dt>
          <dd>{clinicUserEntity.note}</dd>
          <dt>
            <span id="isActive">
              <Translate contentKey="clinicManagementApp.clinicUser.isActive">Is Active</Translate>
            </span>
          </dt>
          <dd>{clinicUserEntity.isActive ? 'true' : 'false'}</dd>
          <dt>
            <span id="displayName">
              <Translate contentKey="clinicManagementApp.clinicUser.displayName">Display Name</Translate>
            </span>
          </dt>
          <dd>{clinicUserEntity.displayName}</dd>
          <dt>
            <Translate contentKey="clinicManagementApp.clinicUser.clinic">Clinic</Translate>
          </dt>
          <dd>{clinicUserEntity.clinic ? clinicUserEntity.clinic.name : ''}</dd>
          <dt>
            <Translate contentKey="clinicManagementApp.clinicUser.user">User</Translate>
          </dt>
          <dd>{clinicUserEntity.user ? clinicUserEntity.user.login : ''}</dd>
        </dl>
        <Button tag={Link} to="/clinic-user" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/clinic-user/${clinicUserEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ClinicUserDetail;
