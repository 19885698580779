// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ==========================================================================
Main page styles
========================================================================== */
.hipster {
  display: inline-block;
  width: 100%;
  height: 250px;
  background: url("/content/images/main_logo.jpg") no-repeat center top;
  background-size: contain;
}

.content {
  margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/home/home.scss"],"names":[],"mappings":"AAAA;;4EAAA;AAGA;EACE,qBAAA;EACA,WAAA;EACA,aAAA;EACA,qEAAA;EACA,wBAAA;AACF;;AACA;EACE,gBAAA;AAEF","sourcesContent":["/* ==========================================================================\nMain page styles\n========================================================================== */\n.hipster {\n  display: inline-block;\n  width: 100%;\n  height: 250px;\n  background: url('/content/images/main_logo.jpg') no-repeat center top;\n  background-size: contain;\n}\n.content {\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
