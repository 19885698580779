import dayjs from 'dayjs';
import { IClinicUser } from 'app/shared/model/clinic-user.model';
import { ICourseOfTreatment } from 'app/shared/model/course-of-treatment.model';

export interface ITreatment {
  id?: number;
  createdDate?: dayjs.Dayjs | null;
  note?: string | null;
  clinicUser?: IClinicUser | null;
  courseOfTreatment?: ICourseOfTreatment | null;
}

export const defaultValue: Readonly<ITreatment> = {};
