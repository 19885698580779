import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormGroup } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeToServer } from 'app/shared/util/date-utils';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getClinics } from 'app/entities/clinic/clinic.reducer';
import { getEntities as getPeople } from 'app/entities/person/person.reducer';
import { getEntity, updateEntity, createEntity, reset } from './course-of-treatment.reducer';
import { Modal } from 'antd';
import PersonListSearch from 'app/components/person-list-search/PersonListSearch';

export const CourseOfTreatmentUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const clinics = useAppSelector(state => state.clinic.entities);
  const people = useAppSelector(state => state.person.entities);
  const courseOfTreatmentEntity = useAppSelector(state => state.courseOfTreatment.entity);
  const loading = useAppSelector(state => state.courseOfTreatment.loading);
  const updating = useAppSelector(state => state.courseOfTreatment.updating);
  const updateSuccess = useAppSelector(state => state.courseOfTreatment.updateSuccess);

  const handleClose = () => {
    navigate('/course-of-treatment');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }

    dispatch(getClinics({}));
    dispatch(getPeople({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    values.fromDate = convertDateTimeToServer(values.fromDate);
    values.toDate = convertDateTimeToServer(values.toDate);
    if (values.numTreatment !== undefined && typeof values.numTreatment !== 'number') {
      values.numTreatment = Number(values.numTreatment);
    }
    if (values.numOpinion !== undefined && typeof values.numOpinion !== 'number') {
      values.numOpinion = Number(values.numOpinion);
    }
    if (values.numCheck !== undefined && typeof values.numCheck !== 'number') {
      values.numCheck = Number(values.numCheck);
    }

    const entity = {
      ...courseOfTreatmentEntity,
      ...values,
      clinic: clinics.find(it => it.id.toString() === values.clinic?.toString()),
      person: people.find(it => it.id.toString() === values.person?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  return (
    <div>
      <Modal title={'Person list'} open={false} width={1200}>
        <PersonListSearch />
      </Modal>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="clinicManagementApp.courseOfTreatment.home.createOrEditLabel" data-cy="CourseOfTreatmentCreateUpdateHeading">
            <Translate contentKey="clinicManagementApp.courseOfTreatment.home.createOrEditLabel">
              Create or edit a CourseOfTreatment
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={{
                ...courseOfTreatmentEntity,
                clinic: courseOfTreatmentEntity?.clinic?.id,
                person: courseOfTreatmentEntity?.person?.id,
              }}
              onSubmit={saveEntity}
            >
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="course-of-treatment-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              {!isNew && (
                <ValidatedField
                  label={translate('clinicManagementApp.courseOfTreatment.conclusion')}
                  id="course-of-treatment-conclusion"
                  name="conclusion"
                  data-cy="conclusion"
                  type="text"
                />
              )}
              <ValidatedField
                label={translate('clinicManagementApp.courseOfTreatment.medicalInfomation')}
                id="course-of-treatment-medicalInfomation"
                name="medicalInfomation"
                data-cy="medicalInfomation"
                type="text"
              />
              <ValidatedField
                label={translate('clinicManagementApp.courseOfTreatment.requestExternalConsult')}
                id="course-of-treatment-requestExternalConsult"
                name="requestExternalConsult"
                data-cy="requestExternalConsult"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('clinicManagementApp.courseOfTreatment.displayName')}
                id="course-of-treatment-displayName"
                name="displayName"
                data-cy="displayName"
                type="text"
              />
              <ValidatedField
                label={translate('clinicManagementApp.courseOfTreatment.isShare')}
                id="course-of-treatment-isShare"
                name="isShare"
                data-cy="isShare"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('clinicManagementApp.courseOfTreatment.noiDungCanChamSoc')}
                id="course-of-treatment-noiDungCanChamSoc"
                name="noiDungCanChamSoc"
                data-cy="noiDungCanChamSoc"
                type="text"
              />
              <ValidatedField
                label={translate('clinicManagementApp.courseOfTreatment.treatmentRegimen')}
                id="course-of-treatment-treatmentRegimen"
                name="treatmentRegimen"
                data-cy="treatmentRegimen"
                type="text"
              />
              <ValidatedField
                id="course-of-treatment-clinic"
                name="clinic"
                data-cy="clinic"
                label={translate('clinicManagementApp.courseOfTreatment.clinic')}
                type="select"
              >
                <option value="" key="0" />
                {clinics
                  ? clinics.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="course-of-treatment-person"
                name="person"
                data-cy="person"
                label={translate('clinicManagementApp.courseOfTreatment.person')}
                type="select"
              >
                <option value="" key="0" />
                {people
                  ? people.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name} - {otherEntity.yob}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/course-of-treatment" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CourseOfTreatmentUpdate;
