import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = (props: { isAdmin: boolean }) => {
  return (
    <>
      {/* prettier-ignore */}
      {props.isAdmin && (
        <>
          <MenuItem icon="asterisk" to="/clinic">
            <Translate contentKey="global.menu.entities.clinic" />
          </MenuItem>
          <MenuItem icon="asterisk" to="/clinic-user">
            <Translate contentKey="global.menu.entities.clinicUser" />
          </MenuItem>
        </>
      )}

      <MenuItem icon="asterisk" to="/person">
        <Translate contentKey="global.menu.entities.person" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/course-of-treatment">
        <Translate contentKey="global.menu.entities.courseOfTreatment" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/medical-specialty">
        <Translate contentKey="global.menu.entities.medicalSpecialty" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/consultant-opinion">
        <Translate contentKey="global.menu.entities.consultantOpinion" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/treatment">
        <Translate contentKey="global.menu.entities.treatment" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/paraclinical-check">
        <Translate contentKey="global.menu.entities.paraclinicalCheck" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
