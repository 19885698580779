// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comment_input_area {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.comment_input_area .send_button {
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/detail-course-of-treatment/comment/CommentInput.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,aALI;AAIN;AAEE;EACE,YAAA;AAAJ","sourcesContent":["$gap: 10px;\n\n.comment_input_area {\n  display: flex;\n  flex-direction: column;\n  row-gap: $gap;\n  .send_button {\n    float: right;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
