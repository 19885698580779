import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ConsultantOpinion from './consultant-opinion';
import ConsultantOpinionDetail from './consultant-opinion-detail';
import ConsultantOpinionUpdate from './consultant-opinion-update';
import ConsultantOpinionDeleteDialog from './consultant-opinion-delete-dialog';

const ConsultantOpinionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ConsultantOpinion />} />
    <Route path="new" element={<ConsultantOpinionUpdate />} />
    <Route path=":id">
      <Route index element={<ConsultantOpinionDetail />} />
      <Route path="edit" element={<ConsultantOpinionUpdate />} />
      <Route path="delete" element={<ConsultantOpinionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ConsultantOpinionRoutes;
