export interface IClinic {
  id?: number;
  name?: string | null;
  address?: string | null;
  tel?: string | null;
  description?: string | null;
  shortName?: string | null;
}

export const defaultValue: Readonly<IClinic> = {};
