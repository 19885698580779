import { useMutation, useQueries } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { Button, Form, Input, Modal, Timeline } from 'antd';
import {
  addParaclinicalCheckAction,
  getConsultantOpinionsListByCourseID,
  getCourseOfTreatmentByID,
  getCurrentClinicUser,
  getParaclinicCheckID,
  getTreatmentListByCourseID,
  sendConclusionTreatment,
  sendConsultantOpinionsByCourse,
  sendTreatmentByCourse,
} from './DetailCourseOfTreatment.action';
import CommentItem from './comment/CommentItem';
import CommentInput from './comment/CommentInput';
import './DetailCourseOfTreatment.scss';
import dayjs from 'dayjs';
import { TreatmentItem, OpinionItem, ConclusionItem, ParaclincalItem } from './treatment/TreatmentItem';
import { useForm } from 'antd/es/form/Form';
import { IParaclinicalCheck } from 'app/shared/model/paraclinical-check.model';

const DetailCourseOfTreatment = () => {
  const { id } = useParams<'id'>();
  const [openModal, setOpenModal] = useState<'TREATMENT' | 'RESULT' | 'PARACLINICAL' | undefined>();
  const [form] = useForm();
  const [formResult] = useForm();
  const [formParaclinical] = useForm();
  const [isCurrentClinicUser, setIsCurrentClinicUser] = useState(null);
  const [isFinishTreatment, setIsFinishTreatment] = useState(false);
  const [
    { data: treatmentData, refetch: treatmentDataRefetch },
    { data: consultantOpinionsData, refetch: consultantOpinionsRefetch },
    { data: courseOfTreatmentData, refetch: courseOfTreatmentDataRefetch },
    { data: paraclinicCheckData, refetch: paraclinicCheckRefetch },
    { data: currentClinicUserData },
  ] = useQueries({
    queries: [
      {
        queryKey: ['treatments'],
        queryFn: () => getTreatmentListByCourseID(id),
      },
      {
        queryKey: ['consultantOpinions'],
        queryFn: () => getConsultantOpinionsListByCourseID(id),
      },
      {
        queryKey: ['courseOfTreatment'],
        queryFn: () => getCourseOfTreatmentByID(id),
      },
      {
        queryKey: ['paraclinicCheck'],
        queryFn: () => getParaclinicCheckID(id),
      },
      {
        queryKey: ['currentClinicUser'],
        queryFn: () => getCurrentClinicUser(),
      },
    ],
  });

  const sendConsultantOpinions = useMutation({
    mutationFn: (opinion: string | null) => {
      return sendConsultantOpinionsByCourse({ detail: opinion, courseOfTreatment: courseOfTreatmentData.data });
    },
    onSuccess: () => {
      consultantOpinionsRefetch();
    },
  });

  const addParaclinicalCheck = useMutation({
    mutationFn: (result: IParaclinicalCheck | null) => {
      return addParaclinicalCheckAction({ ...result, courseOfTreatment: courseOfTreatmentData.data });
    },
    onSuccess: () => {
      paraclinicCheckRefetch();
    },
  });
  const sendConclusion = useMutation({
    mutationFn: (result: string | null) => {
      return sendConclusionTreatment({ ...courseOfTreatmentData.data, conclusion: result });
    },
    onSuccess: () => {
      courseOfTreatmentDataRefetch();
    },
  });

  const sendTreatment = useMutation({
    mutationFn: (note: string) => {
      return sendTreatmentByCourse({ note, courseOfTreatment: courseOfTreatmentData.data });
    },
    onSuccess: () => {
      treatmentDataRefetch();
    },
  });
  useEffect(() => {
    if (courseOfTreatmentData?.data && currentClinicUserData?.data) {
      setIsCurrentClinicUser(currentClinicUserData?.data.clinic?.id === courseOfTreatmentData?.data.clinic?.id);
    }
    if (courseOfTreatmentData?.data) {
      setIsFinishTreatment(!!courseOfTreatmentData?.data.toDate);
    }
  }, [currentClinicUserData, courseOfTreatmentData]);

  const handleListTimeline = () => {
    const treatmentList = treatmentData?.data.map(it => {
      return {
        key: it.id,
        label: dayjs(it.createdDate).format('HH:mm:ss DD/MM/YYYY').toString(),
        children: <TreatmentItem {...it} />,
        date: dayjs(it.createdDate),
        color: 'blue',
      };
    });
    const opinionList = consultantOpinionsData?.data.map(it => {
      return {
        key: it.id,
        label: dayjs(it.createdDate).format('HH:mm:ss DD/MM/YYYY'),
        children: <OpinionItem {...it} />,
        color: 'green',
        date: dayjs(it.createdDate),
      };
    });
    const paraClinicalList = paraclinicCheckData?.data.map(it => {
      return {
        key: it.id,
        label: dayjs(it.createdDate).format('HH:mm:ss DD/MM/YYYY'),
        children: <ParaclincalItem {...it} />,
        color: 'yellow',
        date: dayjs(it.createdDate),
      };
    });

    const totalList = treatmentList
      ?.concat(opinionList)
      ?.concat(paraClinicalList)
      ?.sort((a, b) => {
        if (dayjs(a.date) > dayjs(b.date)) {
          return 1;
        } else if (dayjs(a.date) < dayjs(b.date)) {
          return -1;
        } else {
          return 0;
        }
      });
    if (totalList?.length > 0) {
      if (courseOfTreatmentData?.data?.conclusion) {
        totalList.push({
          key: 999,
          label: dayjs(courseOfTreatmentData.data?.toDate).format('HH:mm:ss DD/MM/YYYY'),
          children: <ConclusionItem {...courseOfTreatmentData?.data} />,
          color: 'red',
          date: dayjs(courseOfTreatmentData.data?.toDate),
        });
      }
      return totalList;
    }

    return [];
  };
  return (
    <section className="course_of_treatments_area">
      <Modal
        open={openModal === 'TREATMENT'}
        title={'Thêm liệu trình'}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          setOpenModal(undefined);
          form.resetFields();
        }}
      >
        <Form
          form={form}
          onFinish={values => {
            sendTreatment.mutate(values.note);
            setOpenModal(undefined);
            form.resetFields();
          }}
        >
          <Form.Item rules={[{ required: true, message: 'Vui lòng nhập liệu trình' }]} name={'note'}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={openModal === 'RESULT'}
        title={'Kết thúc'}
        onOk={() => {
          formResult.submit();
        }}
        onCancel={() => {
          setOpenModal(undefined);
          formResult.resetFields();
        }}
      >
        <Form
          form={formResult}
          onFinish={values => {
            sendConclusion.mutate(values.result);
            setOpenModal(undefined);
            formResult.resetFields();
          }}
        >
          <Form.Item rules={[{ required: true, message: 'Vui lòng nhập kết quả' }]} name={'result'}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={openModal === 'PARACLINICAL'}
        title={'Nhập kết quả khám lâm sàn'}
        onOk={() => {
          formParaclinical.submit();
        }}
        onCancel={() => {
          setOpenModal(undefined);
          formParaclinical.resetFields();
        }}
      >
        <Form
          layout={'vertical'}
          form={formParaclinical}
          onFinish={values => {
            addParaclinicalCheck.mutate(values);
            setOpenModal(undefined);
            formParaclinical.resetFields();
          }}
        >
          <Form.Item label={'Huyết áp tâm thu'} rules={[{ required: true, message: 'Vui lòng nhập huyết áp tâm thu' }]} name={'hatt'}>
            <Input type={'number'} />
          </Form.Item>
          <Form.Item
            label={'Huyết áp tâm trương'}
            rules={[{ required: true, message: 'Vui lòng nhập huyết áp tâm trương' }]}
            name={'hattr'}
          >
            <Input type={'number'} />
          </Form.Item>
          <Form.Item label={'Nhịp tim'} rules={[{ required: true, message: 'Vui lòng nhập nhịp tim' }]} name={'heartRate'}>
            <Input type={'number'} />
          </Form.Item>
          <Form.Item
            label={'Nội dung cần chăm sóc'}
            rules={[{ required: true, message: 'Vui lòng nhập nội dung cần chăm sóc' }]}
            name={'noiDungCanChamSoc'}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <div className="header_area">
        <h2>{courseOfTreatmentData?.data.noiDungCanChamSoc}</h2>
        <Button
          danger
          type="primary"
          className="add_treatment_btn"
          onClick={() => setOpenModal('RESULT')}
          hidden={!(isCurrentClinicUser === true) || isFinishTreatment}
        >
          Nhập kết quả
        </Button>
        <Button
          style={{ backgroundColor: '#0c3e6a' }}
          type="primary"
          className="add_treatment_btn"
          onClick={() => setOpenModal('TREATMENT')}
          hidden={!(isCurrentClinicUser === true) || isFinishTreatment}
        >
          Thêm liệu trình
        </Button>
        <Button
          type="primary"
          style={{ backgroundColor: '#55d6c2' }}
          className="add_treatment_btn"
          onClick={() => setOpenModal('PARACLINICAL')}
          hidden={!(isCurrentClinicUser === true) || isFinishTreatment}
        >
          Khám lâm sàn
        </Button>
      </div>
      <div className="timeline_area">
        <Timeline mode={'left'} items={handleListTimeline()}></Timeline>
      </div>
      <div className="comment_area">
        <CommentInput
          hidden={isFinishTreatment}
          sendOpinion={opinion => {
            if (opinion?.trim() !== '') {
              sendConsultantOpinions.mutate(opinion);
            }
          }}
        />
      </div>
    </section>
  );
};

export default DetailCourseOfTreatment;
