import { IClinicUser } from 'app/shared/model/clinic-user.model';
import { IConsultantOpinion } from 'app/shared/model/consultant-opinion.model';
import { ICourseOfTreatment } from 'app/shared/model/course-of-treatment.model';
import { IParaclinicalCheck } from 'app/shared/model/paraclinical-check.model';
import { ITreatment } from 'app/shared/model/treatment.model';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';

const apiCourseOfTreatmentUrl = 'api/course-of-treatments';
const apiTreatmentUrl = 'api/treatments/byCourse/';
const apiConsultantOpinionsUrl = 'api/consultant-opinions/byCourse/';
const apiConsultantOpinionsBaseUrl = 'api/consultant-opinions';
const apiCourseOfTreatmentConclusionBaseUrl = 'api/course-of-treatments/:id/conclusion';
const apiTreatmentBaseUrl = 'api/treatments';
const apiCurrentClinicUserUrl = 'api/clinic-users/current';
const apiParaclinicalCheckUrl = 'api/paraclinical-checks';
const apiParaclinicalCheckByCourseUrl = 'api/paraclinical-checks/byCourse';

export const getCourseOfTreatmentByID = (id: any) => {
  const requestUrl = `${apiCourseOfTreatmentUrl}/${id}`;
  return axios.get<ICourseOfTreatment>(requestUrl);
};

export const getParaclinicCheckID = (id: any) => {
  const requestUrl = `${apiParaclinicalCheckByCourseUrl}/${id}`;
  return axios.get<IParaclinicalCheck[]>(requestUrl);
};

export const getTreatmentListByCourseID = (id: any) => {
  const requestUrl = `${apiTreatmentUrl}${id}`;
  return axios.get<ITreatment[]>(requestUrl);
};

export const getConsultantOpinionsListByCourseID = (id: any) => {
  const requestUrl = `${apiConsultantOpinionsUrl}${id}`;
  return axios.get<IConsultantOpinion[]>(requestUrl);
};

export const getCurrentClinicUser = () => {
  const requestUrl = `${apiCurrentClinicUserUrl}`;
  return axios.get<IClinicUser>(requestUrl);
};

export const sendConsultantOpinionsByCourse = (entity: IConsultantOpinion) => {
  return axios.post<IConsultantOpinion>(apiConsultantOpinionsBaseUrl, cleanEntity(entity));
};

export const sendTreatmentByCourse = (entity: ITreatment) => {
  return axios.post<ITreatment>(apiTreatmentBaseUrl, cleanEntity(entity));
};

export const sendConclusionTreatment = (entity: ICourseOfTreatment) => {
  return axios.put<ICourseOfTreatment>(apiCourseOfTreatmentConclusionBaseUrl.replace(':id', entity.id.toString()), entity.conclusion, {
    headers: { 'Content-Type': 'text/plain' },
  });
};

export const addParaclinicalCheckAction = async (entity: IParaclinicalCheck) => {
  return axios.post<IParaclinicalCheck>(apiParaclinicalCheckUrl, cleanEntity(entity));
};
