import clinic from 'app/entities/clinic/clinic.reducer';
import clinicUser from 'app/entities/clinic-user/clinic-user.reducer';
import person from 'app/entities/person/person.reducer';
import courseOfTreatment from 'app/entities/course-of-treatment/course-of-treatment.reducer';
import medicalSpecialty from 'app/entities/medical-specialty/medical-specialty.reducer';
import consultantOpinion from 'app/entities/consultant-opinion/consultant-opinion.reducer';
import treatment from 'app/entities/treatment/treatment.reducer';
import paraclinicalCheck from 'app/entities/paraclinical-check/paraclinical-check.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  clinic,
  clinicUser,
  person,
  courseOfTreatment,
  medicalSpecialty,
  consultantOpinion,
  treatment,
  paraclinicalCheck,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
