import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './course-of-treatment.reducer';

export const CourseOfTreatmentDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const courseOfTreatmentEntity = useAppSelector(state => state.courseOfTreatment.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="courseOfTreatmentDetailsHeading">
          <Translate contentKey="clinicManagementApp.courseOfTreatment.detail.title">CourseOfTreatment</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{courseOfTreatmentEntity.id}</dd>
          <dt>
            <span id="fromDate">
              <Translate contentKey="clinicManagementApp.courseOfTreatment.fromDate">From Date</Translate>
            </span>
          </dt>
          <dd>
            {courseOfTreatmentEntity.fromDate ? (
              <TextFormat value={courseOfTreatmentEntity.fromDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="toDate">
              <Translate contentKey="clinicManagementApp.courseOfTreatment.toDate">To Date</Translate>
            </span>
          </dt>
          <dd>
            {courseOfTreatmentEntity.toDate ? (
              <TextFormat value={courseOfTreatmentEntity.toDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="conclusion">
              <Translate contentKey="clinicManagementApp.courseOfTreatment.conclusion">Conclusion</Translate>
            </span>
          </dt>
          <dd>{courseOfTreatmentEntity.conclusion}</dd>
          <dt>
            <span id="medicalInfomation">
              <Translate contentKey="clinicManagementApp.courseOfTreatment.medicalInfomation">Medical Infomation</Translate>
            </span>
          </dt>
          <dd>{courseOfTreatmentEntity.medicalInfomation}</dd>
          <dt>
            <span id="requestExternalConsult">
              <Translate contentKey="clinicManagementApp.courseOfTreatment.requestExternalConsult">Request External Consult</Translate>
            </span>
          </dt>
          <dd>{courseOfTreatmentEntity.requestExternalConsult ? 'true' : 'false'}</dd>
          <dt>
            <span id="displayName">
              <Translate contentKey="clinicManagementApp.courseOfTreatment.displayName">Display Name</Translate>
            </span>
          </dt>
          <dd>{courseOfTreatmentEntity.displayName}</dd>
          <dt>
            <span id="isShare">
              <Translate contentKey="clinicManagementApp.courseOfTreatment.isShare">Is Share</Translate>
            </span>
          </dt>
          <dd>{courseOfTreatmentEntity.isShare ? 'true' : 'false'}</dd>
          <dt>
            <span id="noiDungCanChamSoc">
              <Translate contentKey="clinicManagementApp.courseOfTreatment.noiDungCanChamSoc">Noi Dung Can Cham Soc</Translate>
            </span>
          </dt>
          <dd>{courseOfTreatmentEntity.noiDungCanChamSoc}</dd>
          <dt>
            <span id="treatmentRegimen">
              <Translate contentKey="clinicManagementApp.courseOfTreatment.treatmentRegimen">Treatment Regimen</Translate>
            </span>
          </dt>
          <dd>{courseOfTreatmentEntity.treatmentRegimen}</dd>
          <dt>
            <span id="numTreatment">
              <Translate contentKey="clinicManagementApp.courseOfTreatment.numTreatment">Num Treatment</Translate>
            </span>
          </dt>
          <dd>{courseOfTreatmentEntity.numTreatment}</dd>
          <dt>
            <span id="numOpinion">
              <Translate contentKey="clinicManagementApp.courseOfTreatment.numOpinion">Num Opinion</Translate>
            </span>
          </dt>
          <dd>{courseOfTreatmentEntity.numOpinion}</dd>
          <dt>
            <span id="numCheck">
              <Translate contentKey="clinicManagementApp.courseOfTreatment.numCheck">Num Check</Translate>
            </span>
          </dt>
          <dd>{courseOfTreatmentEntity.numCheck}</dd>
          <dt>
            <span id="shortName">
              <Translate contentKey="clinicManagementApp.courseOfTreatment.shortName">Short Name</Translate>
            </span>
          </dt>
          <dd>{courseOfTreatmentEntity.shortName}</dd>
          <dt>
            <Translate contentKey="clinicManagementApp.courseOfTreatment.clinic">Clinic</Translate>
          </dt>
          <dd>{courseOfTreatmentEntity.clinic ? courseOfTreatmentEntity.clinic.name : ''}</dd>
          <dt>
            <Translate contentKey="clinicManagementApp.courseOfTreatment.person">Person</Translate>
          </dt>
          <dd>{courseOfTreatmentEntity.person ? courseOfTreatmentEntity.person.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/course-of-treatment" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/course-of-treatment/${courseOfTreatmentEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CourseOfTreatmentDetail;
