// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course_of_treatments_area {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.course_of_treatments_area .header_area {
  width: 100%;
}
.course_of_treatments_area .header_area .add_treatment_btn {
  float: right;
  margin-top: 10px;
  width: 100%;
}
.course_of_treatments_area .timeline_area {
  border: 1px solid black;
  padding: 30px;
}
.course_of_treatments_area .timeline_area .ant-timeline-item-label {
  width: calc(30% - 12px);
}
.course_of_treatments_area .timeline_area .ant-timeline-item-tail {
  inset-inline-start: 30%;
}
.course_of_treatments_area .timeline_area .ant-timeline.ant-timeline-label .ant-timeline-item-head {
  inset-inline-start: 30%;
}
.course_of_treatments_area .timeline_area .ant-timeline-item-content {
  inset-inline-start: 30% !important;
  width: calc(70% - 12px) !important;
}
.course_of_treatments_area .comment_area {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.course_of_treatments_area .comment_list {
  display: flex;
  flex-direction: column-reverse;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/detail-course-of-treatment/DetailCourseOfTreatment.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,sBAAA;EACA,aAHM;AAGR;AACE;EACE,WAAA;AACJ;AAAI;EACE,YAAA;EACA,gBARE;EASF,WAAA;AAEN;AACE;EACE,uBAAA;EACA,aAAA;AACJ;AAAI;EACE,uBAAA;AAEN;AAAI;EACE,uBAAA;AAEN;AAAI;EACE,uBAAA;AAEN;AAAI;EACE,kCAAA;EACA,kCAAA;AAEN;AACE;EACE,aAAA;EACA,sBAAA;EACA,aAhCI;AAiCR;AACE;EACE,aAAA;EACA,8BAAA;AACJ","sourcesContent":[".course_of_treatments_area {\n  $gap: 10px;\n  display: flex;\n  flex-direction: column;\n  row-gap: $gap;\n  .header_area {\n    width: 100%;\n    .add_treatment_btn {\n      float: right;\n      margin-top: $gap;\n      width: 100%;\n    }\n  }\n  .timeline_area {\n    border: 1px solid black;\n    padding: 30px;\n    .ant-timeline-item-label {\n      width: calc(30% - 12px);\n    }\n    .ant-timeline-item-tail {\n      inset-inline-start: 30%;\n    }\n    .ant-timeline.ant-timeline-label .ant-timeline-item-head {\n      inset-inline-start: 30%;\n    }\n    .ant-timeline-item-content {\n      inset-inline-start: 30% !important;\n      width: calc(70% - 12px) !important;\n    }\n  }\n  .comment_area {\n    display: flex;\n    flex-direction: column;\n    row-gap: $gap;\n  }\n  .comment_list {\n    display: flex;\n    flex-direction: column-reverse;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
