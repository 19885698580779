import dayjs from 'dayjs';
import { IClinic } from 'app/shared/model/clinic.model';
import { IPerson } from 'app/shared/model/person.model';

export interface ICourseOfTreatment {
  id?: number;
  fromDate?: dayjs.Dayjs | null;
  toDate?: dayjs.Dayjs | null;
  conclusion?: string | null;
  medicalInfomation?: string | null;
  requestExternalConsult?: boolean | null;
  displayName?: string | null;
  isShare?: boolean | null;
  noiDungCanChamSoc?: string | null;
  treatmentRegimen?: string | null;
  numTreatment?: number | null;
  numOpinion?: number | null;
  numCheck?: number | null;
  shortName?: string | null;
  clinic?: IClinic | null;
  person?: IPerson | null;
}

export const defaultValue: Readonly<ICourseOfTreatment> = {
  requestExternalConsult: false,
  isShare: false,
};
