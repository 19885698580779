import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Clinic from './clinic';
import ClinicDetail from './clinic-detail';
import ClinicUpdate from './clinic-update';
import ClinicDeleteDialog from './clinic-delete-dialog';

const ClinicRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Clinic />} />
    <Route path="new" element={<ClinicUpdate />} />
    <Route path=":id">
      <Route index element={<ClinicDetail />} />
      <Route path="edit" element={<ClinicUpdate />} />
      <Route path="delete" element={<ClinicDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ClinicRoutes;
