import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import React from 'react';
import { getEntities, searchEntities } from './action';
import { List } from 'antd-mobile';
import { ICourseOfTreatment } from 'app/shared/model/course-of-treatment.model';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';

const CourseOfTreatmentList: React.FC = () => {
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['courseOfTreatmentList'],
    queryFn: ({ pageParam }) => getEntities({ page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPage) => {
      if (Math.ceil(lastPage.headers['x-total-count'] / 10) > allPage.length + 1) {
        return allPage.length + 1;
      }
      return undefined;
    },
  });
  return (
    <>
      <InfiniteScroll
        dataLength={data?.pages?.map(it => it.data)?.flat()?.length || 0}
        next={fetchNextPage}
        hasMore={hasNextPage}
        loader={<div className="loader">Loading ...</div>}
      >
        <List>
          {data?.pages
            ?.map(it => it.data)
            .flat()
            ?.map((item, index) => {
              return (
                <List.Item
                  key={index}
                  description={<p>{item?.treatmentRegimen}</p>}
                  extra={<small>{item?.requestExternalConsult ? 'Cần tham vấn chuyên gia' : ''}</small>}
                >
                  <Link to={`/course-of-treatment/${item?.id}/detail`}> {item?.displayName}</Link>
                </List.Item>
              );
            })}
        </List>
      </InfiniteScroll>
    </>
  );
};
export default CourseOfTreatmentList;
