import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ClinicUser from './clinic-user';
import ClinicUserDetail from './clinic-user-detail';
import ClinicUserUpdate from './clinic-user-update';
import ClinicUserDeleteDialog from './clinic-user-delete-dialog';

const ClinicUserRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ClinicUser />} />
    <Route path="new" element={<ClinicUserUpdate />} />
    <Route path=":id">
      <Route index element={<ClinicUserDetail />} />
      <Route path="edit" element={<ClinicUserUpdate />} />
      <Route path="delete" element={<ClinicUserDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ClinicUserRoutes;
