import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CourseOfTreatment from './course-of-treatment';
import CourseOfTreatmentDetail from './course-of-treatment-detail';
import CourseOfTreatmentUpdate from './course-of-treatment-update';
import CourseOfTreatmentDeleteDialog from './course-of-treatment-delete-dialog';
import DetailCourseOfTreatment from 'app/components/detail-course-of-treatment/DetailCourseOfTreatment';

const CourseOfTreatmentRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CourseOfTreatment />} />
    <Route path="new" element={<CourseOfTreatmentUpdate />} />
    <Route path=":id">
      <Route index element={<CourseOfTreatmentDetail />} />
      <Route path="edit" element={<CourseOfTreatmentUpdate />} />
      <Route path="delete" element={<CourseOfTreatmentDeleteDialog />} />
      <Route path="detail" element={<DetailCourseOfTreatment />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CourseOfTreatmentRoutes;
