import { ICourseOfTreatment } from 'app/shared/model/course-of-treatment.model';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import axios from 'axios';
const apiUrl = 'api/course-of-treatments';
const apiSearchUrl = 'api/course-of-treatments/_search';

export const searchEntities = async ({ query, page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiSearchUrl}?page=${page}&query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return axios.get<ICourseOfTreatment[]>(requestUrl);
};
export const getEntities = async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<ICourseOfTreatment[]>(requestUrl);
};
