import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, InputGroup, FormGroup, Form, Row, Col, Table } from 'reactstrap';
import { Translate, translate, TextFormat, getPaginationState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { searchEntities, getEntities, reset } from './course-of-treatment.reducer';

export const CourseOfTreatment = (props: { isDashboard?: boolean }) => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();

  const [search, setSearch] = useState('');
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const [sorting, setSorting] = useState(false);

  const courseOfTreatmentList = useAppSelector(state => state.courseOfTreatment.entities);
  const loading = useAppSelector(state => state.courseOfTreatment.loading);
  const links = useAppSelector(state => state.courseOfTreatment.links);
  const updateSuccess = useAppSelector(state => state.courseOfTreatment.updateSuccess);

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        }),
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        }),
      );
    }
  };

  const resetAll = () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  useEffect(() => {
    resetAll();
  }, []);

  const startSearching = e => {
    if (search) {
      dispatch(reset());
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        }),
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    dispatch(reset());
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  useEffect(() => {
    if (updateSuccess) {
      resetAll();
    }
  }, [updateSuccess]);

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage]);

  const handleLoadMore = () => {
    if ((window as any).pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1,
      });
    }
  };

  useEffect(() => {
    if (sorting) {
      getAllEntities();
      setSorting(false);
    }
  }, [sorting, search]);

  const sort = p => () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
    setSorting(true);
  };

  const handleSyncList = () => {
    resetAll();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="course-of-treatment-heading" data-cy="CourseOfTreatmentHeading">
        <Translate contentKey="clinicManagementApp.courseOfTreatment.home.title">Course Of Treatments</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="clinicManagementApp.courseOfTreatment.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link
            to="/course-of-treatment/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="clinicManagementApp.courseOfTreatment.home.createLabel">Create new Course Of Treatment</Translate>
          </Link>
        </div>
      </h2>
      <Row>
        <Col sm="12">
          <Form onSubmit={startSearching}>
            <FormGroup>
              <InputGroup>
                <Input
                  type="text"
                  name="search"
                  defaultValue={search}
                  onChange={handleSearch}
                  placeholder={translate('clinicManagementApp.courseOfTreatment.home.search')}
                />
                <Button className="input-group-addon">
                  <FontAwesomeIcon icon="search" />
                </Button>
                <Button type="reset" className="input-group-addon" onClick={clear}>
                  <FontAwesomeIcon icon="trash" />
                </Button>
              </InputGroup>
            </FormGroup>
          </Form>
        </Col>
      </Row>
      <div className="table-responsive">
        <InfiniteScroll
          dataLength={courseOfTreatmentList ? courseOfTreatmentList.length : 0}
          next={handleLoadMore}
          hasMore={paginationState.activePage - 1 < links.next}
          loader={<div className="loader">Loading ...</div>}
        >
          {courseOfTreatmentList && courseOfTreatmentList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={sort('id')}>
                    <Translate contentKey="clinicManagementApp.courseOfTreatment.id">ID</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                  </th>
                  <th className="hand" onClick={sort('fromDate')}>
                    <Translate contentKey="clinicManagementApp.courseOfTreatment.fromDate">From Date</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('fromDate')} />
                  </th>
                  <th className="hand" onClick={sort('toDate')}>
                    <Translate contentKey="clinicManagementApp.courseOfTreatment.toDate">To Date</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('toDate')} />
                  </th>
                  <th className="hand" onClick={sort('requestExternalConsult')}>
                    <Translate contentKey="clinicManagementApp.courseOfTreatment.requestExternalConsult">
                      Request External Consult
                    </Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('requestExternalConsult')} />
                  </th>
                  <th className="hand" onClick={sort('isShare')}>
                    <Translate contentKey="clinicManagementApp.courseOfTreatment.isShare">Is Share</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('isShare')} />
                  </th>
                  <th className="hand" onClick={sort('treatmentRegimen')}>
                    <Translate contentKey="clinicManagementApp.courseOfTreatment.treatmentRegimen">Treatment Regimen</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('treatmentRegimen')} />
                  </th>
                  <th className="hand" onClick={sort('numTreatment')}>
                    <Translate contentKey="clinicManagementApp.courseOfTreatment.numTreatment">Num Treatment</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('numTreatment')} />
                  </th>
                  <th className="hand" onClick={sort('numOpinion')}>
                    <Translate contentKey="clinicManagementApp.courseOfTreatment.numOpinion">Num Opinion</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('numOpinion')} />
                  </th>
                  <th className="hand" onClick={sort('numCheck')}>
                    <Translate contentKey="clinicManagementApp.courseOfTreatment.numCheck">Num Check</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('numCheck')} />
                  </th>
                  <th className="hand" onClick={sort('shortName')}>
                    <Translate contentKey="clinicManagementApp.courseOfTreatment.shortName">Short Name</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('shortName')} />
                  </th>

                  <th />
                </tr>
              </thead>
              <tbody>
                {courseOfTreatmentList.map((courseOfTreatment, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>
                      <Button tag={Link} to={`/course-of-treatment/${courseOfTreatment.id}`} color="link" size="sm">
                        {courseOfTreatment.id}
                      </Button>
                    </td>
                    <td>{courseOfTreatment.displayName}</td>
                    <td>
                      {courseOfTreatment.fromDate ? (
                        <TextFormat type="date" value={courseOfTreatment.fromDate} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>
                      {courseOfTreatment.toDate ? (
                        <TextFormat type="date" value={courseOfTreatment.toDate} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{courseOfTreatment.requestExternalConsult ? 'true' : 'false'}</td>
                    <td>{courseOfTreatment.isShare ? 'true' : 'false'}</td>
                    <td>{courseOfTreatment.treatmentRegimen}</td>
                    <td>{courseOfTreatment.numTreatment}</td>
                    <td>{courseOfTreatment.numOpinion}</td>
                    <td>{courseOfTreatment.numCheck}</td>
                    <td>{courseOfTreatment.shortName}</td>

                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button
                          tag={Link}
                          to={
                            props.isDashboard
                              ? `/course-of-treatment/${courseOfTreatment.id}/detail`
                              : `/course-of-treatment/${courseOfTreatment.id}`
                          }
                          color="info"
                          size="sm"
                          data-cy="entityDetailsButton"
                        >
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        {!props.isDashboard && (
                          <>
                            <Button
                              tag={Link}
                              to={`/course-of-treatment/${courseOfTreatment.id}/edit`}
                              color="primary"
                              size="sm"
                              data-cy="entityEditButton"
                            >
                              <FontAwesomeIcon icon="pencil-alt" />{' '}
                              <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.edit">Edit</Translate>
                              </span>
                            </Button>
                            <Button
                              onClick={() => (window.location.href = `/course-of-treatment/${courseOfTreatment.id}/delete`)}
                              color="danger"
                              size="sm"
                              data-cy="entityDeleteButton"
                            >
                              <FontAwesomeIcon icon="trash" />{' '}
                              <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.delete">Delete</Translate>
                              </span>
                            </Button>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="clinicManagementApp.courseOfTreatment.home.notFound">No Course Of Treatments found</Translate>
              </div>
            )
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default CourseOfTreatment;
