import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Clinic from './clinic';
import ClinicUser from './clinic-user';
import Person from './person';
import CourseOfTreatment from './course-of-treatment';
import MedicalSpecialty from './medical-specialty';
import ConsultantOpinion from './consultant-opinion';
import Treatment from './treatment';
import ParaclinicalCheck from './paraclinical-check';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="clinic/*" element={<Clinic />} />
        <Route path="clinic-user/*" element={<ClinicUser />} />
        <Route path="person/*" element={<Person />} />
        <Route path="course-of-treatment/*" element={<CourseOfTreatment />} />
        <Route path="medical-specialty/*" element={<MedicalSpecialty />} />
        <Route path="consultant-opinion/*" element={<ConsultantOpinion />} />
        <Route path="treatment/*" element={<Treatment />} />
        <Route path="paraclinical-check/*" element={<ParaclinicalCheck />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
