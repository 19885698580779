// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.treatment_detail {
  display: flex;
  flex-direction: column;
}
.treatment_detail .content_treatment {
  font-size: 16px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/detail-course-of-treatment/treatment/TreatmentItem.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;AACJ","sourcesContent":[".treatment_detail {\n  display: flex;\n  flex-direction: column;\n\n  .content_treatment {\n    font-size: 16px;\n    font-weight: 700;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
