import dayjs from 'dayjs';
import { ICourseOfTreatment } from 'app/shared/model/course-of-treatment.model';

export interface IParaclinicalCheck {
  id?: number;
  hatt?: number | null;
  hattr?: number | null;
  heartRate?: number | null;
  noiDungCanChamSoc?: string | null;
  createdDate?: dayjs.Dayjs | null;
  courseOfTreatment?: ICourseOfTreatment | null;
}

export const defaultValue: Readonly<IParaclinicalCheck> = {};
