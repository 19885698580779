export interface IPerson {
  id?: number;
  name?: string | null;
  tel?: string | null;
  socialId?: string | null;
  yob?: number | null;
  address?: string | null;
  displayName?: string | null;
}

export const defaultValue: Readonly<IPerson> = {};
