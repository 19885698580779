import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import MedicalSpecialty from './medical-specialty';
import MedicalSpecialtyDetail from './medical-specialty-detail';
import MedicalSpecialtyUpdate from './medical-specialty-update';
import MedicalSpecialtyDeleteDialog from './medical-specialty-delete-dialog';

const MedicalSpecialtyRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MedicalSpecialty />} />
    <Route path="new" element={<MedicalSpecialtyUpdate />} />
    <Route path=":id">
      <Route index element={<MedicalSpecialtyDetail />} />
      <Route path="edit" element={<MedicalSpecialtyUpdate />} />
      <Route path="delete" element={<MedicalSpecialtyDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MedicalSpecialtyRoutes;
